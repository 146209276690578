import { Component } from 'solid-js'
import { LandingProps } from '~/components/landing/landing.interfaces'
import styles from '~/components/landing/landing.module.scss'
import BrandLogo from '~/assets/brand/logo-with-tagline.svg'

const Landing: Component<LandingProps> = ({ children, onboarding }) => {
  const landingClasses = {
    [styles.landing]: true,
    [styles.onboarding]: onboarding
  }
  
  return (
    <div classList={landingClasses}>
      <aside>
        <img
          class={styles.illustration}
          src='/assets/images/landing-illustration.jpg'
        />
      </aside>
      <div class={styles.content}>
        <BrandLogo class={styles.logo} />
        {children}
      </div>
    </div>
  )
}

export default Landing
